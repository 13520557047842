<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 offset-col-md-1">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header bg-secondary">
              Bodega
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-dark badge-pill float-right sdw">
                    <i class="fas fa-warehouse text-warning"></i>
                  </span>
                </template>
                <span>Bodega</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-danger badge-pill float-right sdw mr-3 pointer"
                    @click="eliminate()"
                  >
                    <i class="fas fa-trash text-white"></i>
                  </span>
                </template>
                <span>ELiminar Bodega</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-warning badge-pill float-right sdw mr-3 pointer"
                    @click="dialog = true"
                  >
                    <i class="fas fa-edit text-dark"></i>
                  </span>
                </template>
                <span>Edítar Bodega</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{{warehouse_id}}</th>
                    <td>{{warehouse_name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 2°second ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 2°second Card -->
          <div class="card">
            <div class="card-header">
              Detalle bodega
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-warning badge-pill float-right sdw">
                    <i class="fas fa-boxes text-dark"></i>
                  </span>
                </template>
                <span>Listado de Bodegas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-secondary badge-pill float-right sdw pointer mr-3"
                    @click="reinitializeWarehouseStock()"
                  >
                    <i class="fas fa-sync text-success"></i>
                  </span>
                </template>
                <span>Actualizar Tabla</span>
              </v-tooltip>
             <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @click="exportExcel"
                  class="badge badge-success badge-pill float-right sdw mr-3"
                >
                  <i class="fas fa-file-excel text-white"></i>
                </span>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="warehouse_stock"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :total-visible="10"
                :search="search"
                sort-by="product.id"
                class="elevation-1 datatable"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <!-- <v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                    ></v-text-field>-->
                    <div class="kt-input-icon kt-input-icon--left">
                      <input
                        v-model="search"
                        append-icon="search"
                        label="Búsqueda"
                        type="text"
                        class="form-control form-control-md col-md-8"
                        placeholder="Buscar..."
                      />
                      <span class="kt-input-icon__icon">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on}">
                      <router-link
                        v-if="item.product != null"
                        :to="`/inventario/stock/productos/${item.product.sku}`"
                      >
                        <v-icon v-on="on" color="#93a2dd" small class="mr-2">mdi-eye</v-icon>
                      </router-link>
                    </template>
                    <span>Ver Producto</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="#87CEFA"
                    :loading="loading"
                    @click="reinitializeWarehouseStock()"
                  >Cargando ..</v-btn>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
              </div>
              <!--Dialog--->
              <v-dialog v-model="dialog" width="600" persistent>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-card>
                        <v-card-title>Editar Bodega</v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="2" v-if="warehouse_id">
                                  <v-text-field
                                    v-model="warehouse_id"
                                    readonly="readonly"
                                    label="ID"
                                    prepend-icon="vpn_key"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="warehouse_name"
                                    :rules="[v => !!v || 'El nombre de la Bodega es requerido!']"
                                    label="Bodega"
                                    prepend-icon="add_business"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#FFD700" class="mx-2" fab dark small @click="editBodega()">
                            <v-icon color="#000000" dark>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="#FF6347"
                            @click="dialog = false"
                          >
                            <v-icon dark>mdi-close</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-dialog>
              <!--End Dialog--->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    boolean: false,
    dialog: false,
    valid: true,
    loading: false,
    search: "",
    warehouse_id: "",
    warehouse_name: "",
    warehouse_stock: [],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "product.id",
      },
      { text: "Producto", value: "product.name" },
      { text: "Stock", value: "quantity" },
      { text: "S.Fisico", value: "physical_quantity" },
      { text: "S.Reservado", value: "reserved_quantity" },
      { text: "Categoria", value: "product.category.name" },
      { text: "Locación", value: "location.code" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    defaultRequiredRules: [(v) => !!v || "Este campo es obligatorio"],
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Listado Bodega", route: "/inventario/warehouse" },
      {
        title: "➁ Detalle Bodega",
        route: `/inventario/warehouse/${this.id}`,
      },
    ]);
    this.loading = true;
    this.fetchWarehouse();
    this.fetchStock();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    //this.initialize();
  },
  methods: {
    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editBodega() {
      var vm = this;

      if (!vm.warehouse_name.length || vm.warehouse_name == null) {
        vm.$bvToast.toast("El nombre de la Bodega no debe ir vacío!", {
          title: `Formulario Invalido`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-center",
        });
        vm.$refs.form.$el[1].focus();
        return;
      }
      if (this.$refs.form.validate()) {
        this.axios({
          url: "inventory/warehouse/" + vm.warehouse_id,
          method: "PUT",
          data: {
            name: vm.warehouse_name,
          },
        })
          .then((response) => {
            vm.fetchWarehouse();

            vm.$bvToast.toast(
              `La Bodega: ${vm.warehouse_name}, ha sido actualizada el ${vm.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.close();
    },
    eliminate() {
      var vm = this;

      const confirmacion = confirm(
        `Esta seguro de eliminar la Bodega: ${vm.warehouse_name} || ID: ${vm.warehouse_id}?`
      );

      if (confirmacion) {
        //set to localStorage to persist alert data on the redirected route
        localStorage.setItem(
          "deleteWarehouse",
          JSON.stringify({
            id: vm.warehouse_id,
            name: vm.warehouse_name,
          })
        );

        this.axios({
          url: "inventory/warehouse/" + vm.warehouse_id,
          method: "DELETE",
        })
          .then((response) => {
            vm.$bvToast.toast(
              `La ${vm.warehouse_name} ha sido eliminada, el ${vm.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
            //rediect to warehouse
            vm.$router.push("/inventario/warehouse");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fetchWarehouse() {
      var vm = this;
      this.axios({
        url: "inventory/warehouse/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          vm.warehouse_id = response.data.id;
          vm.warehouse_name = response.data.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStock() {
      var vm = this;
      this.axios({
        url: "inventory/stock/warehouse/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          //console.log(response.data);
          this.loading = false;
          vm.pageCount = Math.ceil(response.data.data.length / 10);
          vm.warehouse_stock = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reinitializeWarehouseStock() {
      this.warehouse_stock = [];
      this.loading = true;
      this.fetchStock();
    },
    exportExcel() {
      var vm = this;
      let headers = vm.headers.map((e) => e.text);
      let datos = vm.warehouse_stock;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>